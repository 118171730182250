<template>
  <div class="program">
    <!-- 主页标题 -->
    <div class="programTitle">方案分享</div>
    <!-- 方案主体 -->
    <div class="programMain">
      <!-- 方案分类 -->
      <div class="programMain_sort" ref="mark">
        <div
          :style="item.id == queryVal.cat_id ? 'background-color: #fdd30f;' : ''"
          class="programMain_sortItem"
          v-for="(item,index) in planSort"
          :key="index"
          @click="sortTab(item.id)"
        >{{item.title}}</div>
      </div>
      <!-- 方案搜索 -->
      <div :class="state ? 'programMain_search programMain_searchFixed' : 'programMain_search'">
        <div>
          <input v-model="queryVal.title" type="text" placeholder="请输入关键字" />
        </div>
        <div v-if="queryVal.title" @click="clearVal()">
          <i class="el-icon-error"></i>
        </div>
        <div @click="confirmVal()">
          <i class="el-icon-search"></i>
        </div>
      </div>
      <!-- 方案排序 -->
      <div class="programMain_array">
        <div
          :style="queryVal.new ? 'background-color: #fdd30f;' : ''"
          class="programMain_arrayUpload"
          @click="newestUpload()"
        >最新上传</div>
        <div
          :style="queryVal.hot ? 'background-color: #fdd30f;' : ''"
          class="programMain_arrayDownload"
          @click="hottestDownloads()"
        >最热下载</div>
      </div>
      <!-- 方案内容 -->
      <div class="programMain_content">
        <!-- 方案内容项 -->
        <div
          class="programMain_contentItem"
          v-for="(item,index) in planList"
          :key="index"
          @click="goHomeDetails(item)"
        >
          <!-- 内容项标题 -->
          <div class="programMain_contentItem_title">{{item.title}}</div>
          <!-- 内容项图片 -->
          <div class="programMain_contentItem_img">
            <img :src="item.cover" />
          </div>
          <!-- 内容项信息 -->
          <div class="programMain_contentItem_info">
            <div class="programMain_contentItem_infoRight">
              <div class="infoRight_avatar">
                <img :src="item.picture" />
              </div>
              <div class="infoRight_userName">{{item.username}}</div>
            </div>
            <div class="programMain_contentItem_infoLeft">
              <div>
                <div>
                  <i class="el-icon-view"></i>
                </div>
                <div>{{item.visit_num}}</div>
              </div>
              <div>
                <div>
                  <i class="iconfont icondianzan"></i>
                </div>
                <div>{{item.like_num}}</div>
              </div>
            </div>
          </div>
          <!-- 时间 -->
          <div style="text-align: end; font-size: 13px; color: #888;">{{item.created}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
export default {
  data() {
    return {
      // 滑动状态
      state: false,
      // 发送的值
      queryVal: {
        page: 1,
        title: "",
        username: "",
        cat_id: "",
        hot: false,
        new: false
      },
      // 方案数据
      planList: [],
      // 方案分类
      planSort: []
    };
  },
  computed: {
    queryValTitle() {
      return this.queryVal.title;
    }
  },
  watch: {
    // 监听输入框，如果输入框数据为空，则重新请求初始数据
    queryValTitle(val) {
      if (!val) {
        this.queryVal.page = 1;
        this.getPlanList();
      }
    }
  },
  created() {
    this.getPlanList();
    this.getPlanSort();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrolls, true);
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScrolls, true); // 离开页面移除滚动加载事件
    window.removeEventListener("scroll", this.handleScroll, true); //  离开页面清除（移除）滚轮滚动事件
  },
  methods: {
    // 滚动粘顶
    handleScroll() {
      let scrollTop = this.$refs.mark.getBoundingClientRect().top || null;
      let scrollHeight = this.$refs.mark.offsetHeight;
      let scrollBottom = scrollTop + scrollHeight;
      if (scrollBottom <= 0) {
        this.state = true;
      } else {
        this.state = false;
      }
    },
    // 清除搜索框中的值
    clearVal() {
      this.queryVal.page = 1;
      this.queryVal.title = "";
      this.getPlanList();
    },
    // 获取列表数据
    getPlanList() {
      let queryVal = this.queryVal;
      this.$axios({
        url: this.$api + "/auth/ppt/indexlist",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token")
          // "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          page: queryVal.page,
          title: queryVal.title,
          username: queryVal.username,
          cat_id: queryVal.cat_id,
          hot: queryVal.hot,
          new: queryVal.new
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.planList = res.data.data.res;
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error"
          });
        }
      });
    },
    // 获取分类数据
    getPlanSort() {
      this.$axios({
        url: this.$api + "/auth/ppt/cat/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;"
        }
      }).then(res => {
        if (res.data.code == 200) {
          let sortData = [{ title: "全部", id: "" }];
          let sortList = res.data.data;
          sortList.map(item => {
            item.children.map(item => {
              sortData.push(item);
            });
          });
          this.planSort = sortData;
        }
      });
    },
    // 最新上传
    newestUpload() {
      this.queryVal.page = 1;
      this.queryVal.new = !this.queryVal.new;
      this.getPlanList();
    },
    // 最热下载
    hottestDownloads() {
      this.queryVal.page = 1;
      this.queryVal.hot = !this.queryVal.hot;
      this.getPlanList();
    },
    // 确认搜索
    confirmVal() {
      this.queryVal.page = 1;
      this.getPlanList();
    },
    // 处理滚动加载的数据
    handleScrolls() {
      let scrollTop = document.documentElement.scrollTop; //滚动条的高
      let screenHeight = window.screen.availHeight; //当前屏幕的高
      let documentTop = document.body.scrollHeight; //全部内容的高
      let queryVal = this.queryVal;
      if (scrollTop + screenHeight + 10 >= documentTop) {
        this.$axios({
          url: this.$api + "/auth/ppt/indexlist",
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("user_token"),
            "Content-Type": "application/json;charset=UTF-8;"
          },
          params: {
            page: (queryVal.page += 1),
            title: queryVal.title,
            username: queryVal.username,
            cat_id: queryVal.cat_id,
            hot: queryVal.hot,
            new: queryVal.new
          }
        }).then(res => {
          if (res.data.code == 200) {
            let list = res.data.data.res;
            let listArr = this.planList;
            listArr.push.apply(listArr, list);
            this.planList = listArr;
          }
        });
      }
    },
    // 分类切换
    sortTab(id) {
      this.queryVal.page = 1;
      if (id == this.queryVal.cat_id) {
        this.queryVal.cat_id = "";
      } else {
        this.queryVal.cat_id = id;
      }
      this.getPlanList();
    },
    // 跳转详情页
    goHomeDetails(e) {
      this.$router.push({
        path: "/mHomeDetails",
        query: {
          id: e.id,
          visit: e.visit_num
        }
      });
    }
  }
};
</script>

<style scoped>
.program {
  position: relative;
  margin-bottom: 50px;
}
/* 方案标题 */
.programTitle {
  text-align: center;
  color: #000;
  padding: 3% 0;
  background-color: #fdd30f;
}

/* 方案主体-方案分类 */
.programMain_sort {
  font-size: 12px;
  margin: 3% 0;
  padding: 2%;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
/* 方案分类项 */
.programMain_sortItem {
  margin: 5px;
  padding: 5px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
}
/* 方案主体-方案搜索 */
.programMain_search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 10px 0;
  margin: 3% auto;
  background-color: #fff;
}
.programMain_searchFixed {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 0;
  margin: 0;
  position: fixed;
  top: 0;
  box-shadow: 0 3px 3px #e3e3e3;
  background-color: #fff;
}
.programMain_search > div:nth-of-type(1) {
  width: 80%;
  overflow: hidden;
}
.programMain_search > div:nth-of-type(2) {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  background-color: #fff;
}
.programMain_search > div:nth-of-type(3) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  font-size: 1rem;
}
.programMain_search input {
  width: 95%;
  font-size: 1rem;
  padding: 0 10px;
  border: none;
  outline: none;
}
/* 方案主体-方案排序 */
.programMain_array {
  width: 90%;
  font-size: 14px;
  margin: 3% auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.programMain_arrayUpload {
  padding: 1% 3%;
  margin: 0 1%;
  border-radius: 3px;
}
.programMain_arrayDownload {
  padding: 1% 3%;
  margin: 0 1%;
  border-radius: 3px;
}
/* 方案主体-方案内容 */
.programMain_content {
  margin: 3% auto;
}
.programMain_contentItem {
  width: 94%;
  margin: 3% auto;
  padding: 3%;
  background-color: #fff;
}
/* 方案内容-标题 */
.programMain_contentItem_title {
  font-size: 16px;
  font-weight: 600;
  margin: 2% 0;
}
/* 方案内容-图片 */
.programMain_contentItem_img {
  margin: 2% 0;
  width: 100%;
  border-radius: 3%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.programMain_contentItem_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* 方案内容-信息 */
.programMain_contentItem_info {
  margin: 3% 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.programMain_contentItem_infoRight {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 16px;
}
.programMain_contentItem_infoRight > div {
  margin: 0 2%;
}
.programMain_contentItem_infoLeft {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.programMain_contentItem_infoLeft > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0 2%;
}
.programMain_contentItem_infoLeft > div > div {
  margin: 0 3px;
}
.programMain_contentItem_infoLeft > div i {
  font-size: 18px;
}
/* 方案内容-头像 */
.infoRight_avatar {
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 100%;
  overflow: hidden;
}
.infoRight_avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>